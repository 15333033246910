<template>
  <div>
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="#page-top">Thyago.dev.br</a>
        <button
            class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded"
            type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
<!--          <ul class="navbar-nav ml-auto">-->
<!--            <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"-->
<!--                                                 href="#about">Sobre</a></li>-->
<!--          </ul>-->
        </div>
      </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead bg-primary text-white text-center">
      <div class="container align-items-center">
        <div class="row">
          <div class="col-4">
            <!-- Masthead Avatar Image-->
            <img class="masthead-avatar mb-5" src="assets/img/avataaars.svg" alt=""/>
            <!-- Masthead Heading-->
            <h1 class="masthead-heading text-uppercase mb-0">Thyago.dev.br</h1>
            <!-- Icon Divider-->
            <div class="divider-custom divider-light">
              <div class="divider-custom-line"></div>
              <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
              <div class="divider-custom-line"></div>
            </div>
          </div>
          <div class="col-8">
            <h3>Dólar: R$ {{ dolar }} em {{ data }}</h3>
          </div>
        </div>
      </div>
    </header>
    <!-- About Section-->
<!--    <section class="page-section mb-0" id="about">-->
<!--      <div class="container">-->
<!--        &lt;!&ndash; About Section Heading&ndash;&gt;-->
<!--        <h2 class="page-section-heading text-center text-uppercase">Sobre</h2>-->
<!--        &lt;!&ndash; Icon Divider&ndash;&gt;-->
<!--        <div class="divider-custom divider-light">-->
<!--          <div class="divider-custom-line"></div>-->
<!--          <div class="divider-custom-icon"><i class="fas fa-star"></i></div>-->
<!--          <div class="divider-custom-line"></div>-->
<!--        </div>-->
<!--        &lt;!&ndash; About Section Content&ndash;&gt;-->
<!--        <div class="row">-->
<!--          <div class="col-lg-4 ml-auto"><p class="lead">Freelancer is a free bootstrap theme created by Start Bootstrap.-->
<!--            The download includes the complete source files including HTML, CSS, and JavaScript as well as optional SASS-->
<!--            stylesheets for easy customization.</p></div>-->
<!--          <div class="col-lg-4 mr-auto"><p class="lead">You can create your own custom avatar for the masthead, change-->
<!--            the icon in the dividers, and add your email address to the contact form to make it fully functional!</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<script>

import axios from 'axios'

const URL = "https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarPeriodo(dataInicial=@dataInicial,dataFinalCotacao=@dataFinalCotacao)";

export default {
  name: 'HelloWorld',
  data: function () {
    return {
      dolar: null,
      data: null,
    };
  },

  mounted() {
    this.getDolar();
  },

  methods: {
    getDolar: function () {
      axios.get(URL, {
        params: {
          "@dataInicial": `'${this.$moment().subtract(7, 'days').format('MM-DD-YYYY')}'`,
          "@dataFinalCotacao": `'${this.$moment().format('MM-DD-YYYY')}'`,
          '$top': 100,
          '$format': 'json',
          '$select': 'cotacaoCompra,cotacaoVenda,dataHoraCotacao',
        }
      })
          .then(response => {
            // JSON responses are automatically parsed.
            let total = response.data.value.length
            this.dolar = response.data.value[total-1].cotacaoCompra;
            this.data = this.$moment(response.data.value[total-1].dataHoraCotacao).format("DD/MM/YYYY HH:mm");
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
}
</script>
